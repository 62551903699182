<script>
  import { conf, permissions, modal } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, genCode, anchor, clipBoard } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.controllers) $modal={
      cancel:true,
      title:i18n("licence--No more controllers"), 
      msg:i18n("licence--No more controllers msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/controllers/edit")
  }

  async function bulk(){
    setRoute("/processings/bulk")
  }

  async function edit(id){
    setRoute(`/controllers/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/controllers/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/controllers")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/controllers/get/${id}`)
    obj={ ...{dpo:{}, ...obj }}
  }

  isSame("listControllers","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listControllers", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/controllers/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/controllers/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/controllers")
    }}
  }

  let processors=[]
  getProcessors()
  async function getProcessors(){
    processors = await api(`/app/customers/processors/list`)
  }

  let tags=[]
  getTags()
  async function getTags(){
    //tags = await api('/app/customers/controllers/tags/list')
  }

  let dpoEmailAuto=""
  $: {
    let name=obj.name && obj.name.replace(/[^a-zA-Z0-9-_]/g, '') || 'name'
    let ret=`dpo.${name}.${obj.shortId || 'xxxxx'}@dplex.eu`
    dpoEmailAuto = ret.toLowerCase()
  }

  let exportProcessings=`https://api.dplex.eu/v4/export/processors/processings`
  let exportPDpo=`https://api.dplex.eu/v4/export/processors/dpo`
</script>


<CustomerBanner/>


<Route path="/controllers/*" pathHide="/controllers/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}
    />

    <List type="card" cardShadow cardUp cardFooterDate="date" refresh={()=>list()} title="g--controllers" {objs} fields={[
      {id:"name", title:"g--Name"},
      {id:"date", title:"g--Update date", format:'dmy'},
    ]}>
      <div slot="header" class="w-100 d-flex justify-content-between" let:obj>
        <div><I me building/>{obj.name}</div>
        <div><button class="btn btn-sm btn-success" on:click={()=>edit(obj.id)}><I pen-to-square me/><I18n>Modify</I18n></button></div>
      </div>
      <div slot="body" let:obj>
        <div>{obj.address}</div>
        <div>{obj.city}</div>
        <div>{obj.state}</div>
        <div>{obj.country}</div>
        <div>{obj.dpo.email}</div>
        <div>Ref : {obj.shortId}</div>
        <hr><I18n>Informations à communiquer aux personnes concernées par vos traitements</I18n>
        <div class="small"><I18n>Vous pouvez les mettre en lien ou dans une iframe sur votre site</I18n></div>
    
        <ul>
          <li>
            <a href={`${exportProcessings}/${obj.shortId}`}>Politique PUBLIQUE de gestion des données personnelles</a>
            <I clipboard title="Copy link" onclick={()=>clipBoard(`${exportProcessings}/${obj.shortId}`, true)}/>
          </li>
          <li>
            <a href={`${exportProcessings}/${obj.shortId}/global`}>Politique GLOBALE de gestion des données personnelles</a>
            <I clipboard title="Copy link" onclick={()=>clipBoard(`${exportProcessings}/${obj.shortId}/global`, true)}/>
          </li>
          <li>
            <a href={`${exportPDpo}/${obj.shortId}`}>Coordonnées du DPO</a>
            <I clipboard title="Copy link" onclick={()=>clipBoard(`${exportPDpo}/${obj.shortId}`, true)}/>
          </li>
        </ul>
      </div>
    </List>
  </div>
</Route>


<Route path="/controllers/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="controller" detail={obj.name} back="/controllers"/>

      <div class="anchor" on:click={anchor}>
        <span><I18n>Organisation</I18n></span> |
        <span><I18n>DPO</I18n></span> |
        <span><I18n>Options</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-building" label="Organisation" help="Entité portant la responsabilité juridiques des traitements qui lui sertont rattachés"/>
          <Input set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="Référence"/>
          <Input bind:value={obj.name} required type="text" icon label="Nom"/>
          <!--<Input bind:value={obj.tags} canAdd options={tags} type="tag" label="g--tags"/>-->
          <Input bind:value={obj.address} required type="text" icon label="Address"/>
          <Input bind:value={obj.zip} required type="text" icon label="zip" maxLength=8/>
          <Input bind:value={obj.city} required type="text" icon label="city"/>
          <Input bind:value={obj.state} type="text" icon label="state"/>
          <Input bind:value={obj.country} required set={"France"} type="select" icon label="country" options={[ 
            'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia',
            'Finland', 'France', 'Germany', 'Greece', 'Guadeloupe', 'Hungary', 'Iceland',
            'Ireland', 'Italy', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Martinique', 
            'Monaco', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Reunion', 'Romania', 'San Marino', 'Slovak Republic', 
            'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'United Kingdom'
          ]}/>

          <Input type="hr" icon="fa-user-shield" label="DPO" help="Vous devez nommer un délégué à la protection de données (DPD) qui pourra répondre à toutes demandes concernant les données personnelles"/>
         
          <Input bind:value={obj.dpo.name} required type="text" icon="name" label="DPO Name"/>
          <Input bind:value={obj.dpo.reference} type="text" icon="fa-hashtag" label="Numéro CNIL du DPO (DPO-XXX)"/>
          <Input bind:value={obj.dpo.external} type="text" icon="fa-building" label="Nom de la société si DPO externalisé"/>
          <Input bind:value={obj.dpo.email} required type="email" icon label="Email"/>
          <Input bind:value={obj.dpo.address} type="text" icon label="Address"/>
          <Input bind:value={obj.dpo.zip} type="text" icon label="zip" maxLength=8/>
          <Input bind:value={obj.dpo.city} type="text" icon label="city"/>
          <Input bind:value={obj.dpo.state} type="text" icon label="state"/>
          <Input bind:value={obj.dpo.country} set={"France"} type="select" icon label="country" options={[ 
            'Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia',
            'Finland', 'France', 'Germany', 'Greece', 'Guadeloupe', 'Hungary', 'Iceland',
            'Ireland', 'Italy', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Martinique', 
            'Monaco', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Reunion', 'Romania', 'San Marino', 'Slovak Republic', 
            'Slovenia', 'Spain', 'Sweden', 'Switzerland', 'United Kingdom'
          ]}/>

          <Input type="hr" icon="fa-sliders" label="options"/>
          
          <Input bind:value={obj.useRequestManager} set={true} lg type="switch" icon="fa-triangle-exclamation" 
            label="Utiliser le gestionnaire de demande intégré" 
            helpBefore="Le gestionnaire de demande intégré peut directement recevoir les mails des personnes concernées sur une adresse email dédiée {dpoEmailAuto}"
            option="oui"/>
  
         
          <Input bind:value={obj.policyAccessCidr} type="text" placeholder="192.168.10.0/24" icon="network-wired" label="CIDR" help="Vous pouvez limiter l'accès à la politique de sécurité GLOBALE à un réseau spécifique"/>


          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>