<script>
  import { conf, permissions, modal, token, customerId  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/actions/edit")
  }

  async function bulk(){
    setRoute("/actions/bulk")
  }

  async function edit(id){
    setRoute(`/actions/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/actions/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/actions")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/actions/get/${id}`)
    obj={ ...{audit:{}, ...obj }}
  }

  isSame("listActions","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listActions", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/actions/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/actions/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/actions")
    }}
  }

  let controllers=[]
  getControllers()
  async function getControllers(){
    controllers = await api(`/app/customers/controllers/list`)
  }

  function getController(id){
    let ret
    ret=getById(controllers,id)
    return ret.name || '-'
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/actions/tags/list')
  }

  function dl(){
    window.open(`${$conf.apiUrl}/export/actions/${$customerId}?token=${$token}`)
  }

</script>


<CustomerBanner/>


<Route path="/actions/*" pathHide="/actions/edit/:id?" fsContent>
  <div class="container-fluid fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false} {dl}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'status', title:'g--By status', desc:true},
        { id:'controller', title:'g--By controller'},
        { id:'owner', title:'g--By owner'},
        { id:'plannedDate', title:'g--By planned Date', desc:true},
        { id:'closeDate', title:'g--By close Date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--actions" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      {id:"status", title:"g--status", format:'i18n', i18nPrefix:'actions--'},
      {id:"controller", title:"g--Controller", format:getController},
      {id:"name", title:"g--name"},
      {id:"owner", title:"g--owner"},
      {id:"closeDate", title:"g--Close date", format:'dmy'},
      {id:"plannedDate", title:"g--Planned date", format:'dmy'}
    ]}/>
  </div>
</Route>


<Route path="/actions/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="action" detail={obj.name} back="/actions"/>

      <!--div class="anchor" on:click={anchor}>
        <span><I18n>Description</I18n></span>
      </div-->

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="Description"/>
          <Input bind:value={obj.controller} type="select" required icon="fa-building" label="Responsable du traitement" helpBefore="Le responsable de traitement est la personne morale (entreprise, commune, etc.) ou physique qui détermine les finalités et les moyens d'un traitement, c'est à dire l'objectif et la façon de le réaliser" options={controllers}/>
          <Input set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="Référence"/>
          <Input inline bind:value={obj.status} set={"draft"} type="radio" icon="fa-tag" label="status" options={[ 
            { value: "draft", label: "action--draft"},
            { value: "planned", label: "action--planned"},
            { value: "inprogress", label: "action--inprogress"},
            { value: "closed", label: "action--closed"}
            ]}/>
            
          <Input set={new Date()} class="col-sm-6" bind:value={obj.plannedDate} icon type="date" label="Date prévisionnelle" required/>
          {#if obj.status=="closed"}
            <Input set={new Date()} class="col-sm-6" bind:value={obj.closeDate} icon type="date" label="Date de réalisation" required/>
          {/if}

          <Input inline bind:value={obj.priority} set={"normal"} type="radio" icon="fa-highlighter" label="priority" options={[ 
            { value: "normal", label: "action--normal"},
            { value: "high", label: "action--high"}
          ]}/>
          <Input bind:value={obj.name} required type="text" icon label="Nom"/>
          <Input bind:value={obj.description} required type="textarea" rows=10 label="Détail de l'action"/>
          <Input bind:value={obj.owner} required type="textarea" rows=5 label="Acteurs"/>

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>