<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.processors) $modal={
      cancel:true,
      title:i18n("licence--No more processors"), 
      msg:i18n("licence--No more processors msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/processors/edit")
  }

  async function bulk(){
    setRoute("/processors/bulk")
  }

  async function edit(id){
    setRoute(`/processors/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/processors/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/processors")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/processors/get/${id}`)
    obj={ ...{audit:{}, ...obj }}
  }

  isSame("listProcessors","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listProcessors", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/processors/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/processors/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/processors")
    }}
  }

  let controllers=[]
  getControllers()
  async function getControllers(){
    controllers = await api(`/app/customers/controllers/list`)
  }

  function getController(id){
    let ret
    ret=getById(controllers,id)
    return ret.name || '-'
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/processors/tags/list')
  }

</script>


<CustomerBanner/>


<Route path="/processors/*" pathHide="/processors/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={$permissions.bulk?bulk:false}

      bind:filter filters={[
        { filter: {}, title:'g--All' },
        { filter: {valid:true}, title:'g--In use' },
        { filter: {compliant:true}, title:'g--compliant' },
        { filter: {compliant:false}, title:'g--non compliant' },
        { filter: {valid:false}, title:'g--Draft' },
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'controller', title:'g--By controller'},
        { id:'date', title:'g--By date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--processors" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      {id:"compliant", title:"Compliant"},
      {id:"valid", title:"g--In use"},
      {id:"name", title:"g--Name"},
      //{id:"controller", title:"g--Controller", format:getController},
      {id:"tags", title:"g--Tags", format:"tags"},
      {id:"date", title:"g--Update date", format:'dmy'},
      {id:"statusComment", title:"Commentaires"}
    ]}/>
  </div>
</Route>


<Route path="/processors/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="processor" detail={obj.name} back="/processors"/>
      
      <div class="anchor" on:click={anchor}>
        <span><I18n>Général</I18n></span> |
        <span><I18n>Traitements</I18n></span> |
        <span><I18n>Sécurité</I18n></span> |
        <span><I18n>Transferts</I18n></span> |
        <span><I18n>Conformité</I18n></span> |
        <span><I18n>Audit du sous traitant</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="Général"/>
          <!--<Input bind:value={obj.controller} type="select" required icon="fa-building" label="Responsable du traitement" helpBefore="Le responsable de traitement est la personne morale (entreprise, commune, etc.) ou physique qui détermine les finalités et les moyens d'un traitement, c'est à dire l'objectif et la façon de le réaliser" options={controllers}/>-->
          <Input set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="Référence"/>
          <Input bind:value={obj.name} required type="text" icon="fa-clipboard-list-check" label="Nom du sous-traitant"/>
          <Input bind:value={obj.valid} set={true} lg type="switch" icon="fa-play-circle" label="Ce sous-traitant est actif" helpBefore="Ce traitement est actuellement utilisé" option="oui"/>
          <Input bind:value={obj.tags} canAdd options={tags} type="tag" label="g--tags"/>
          <Input bind:value={obj.description} type="textarea" rows=6 icon="fa-comment" label="Description ou détail sur ce sous-traitant"/>
          
          <Input bind:value={obj.dpo} type="textarea" rows=6 icon="fa-comment" label="Nom et coordonnées du délégué à la protection des données de ce sous-traitant" helpBefore="Nom, adresse, email, téléphone..."/>
          <Input bind:value={obj.isContact} set={false} type="switch" lg icon="fa-file-contract" label="Contat de sous-traitance Article 28 ?" option="oui" help="Le traitement par un sous-traitant est régi par un contrat ou un autre acte juridique au titre du droit de l'Union ou du droit d'un État membre, qui lie le sous-traitant à l'égard du responsable du traitement, définit l'objet et la durée du traitement, la nature et la finalité du traitement, le type de données à caractère personnel et les catégories de personnes concernées, et les obligations et les droits du responsable du traitement."/>
      
          <Input bind:value={obj.policyUrl} type="url" icon="fa-link" label="Lien vers la politique de gestion des données personnelles du sous-traitant"/>
          
          <Input type="hr" icon="fa-cogs" label="Traitements"/>
          <Input bind:value={obj.categories} type="textarea" rows=6 icon="fa-comment" label="Indiquez la natures des traitements effectués par ce sous-traitant"/>
          
          <Input type="hr" icon="fa-info-square" label="Sécurité"/>
          <Input bind:value={obj.lawfulness} set={{}} type="checkbox" icon="fa-balance-scale" label="Sécurité mise en oeuvre" helpBefore="Ce sous-traitant mets en oeuvre les mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté au risque" options={[ 
            { value:"a", label: "La pseudonymisation et le chiffrement des données à caractère personnel"},
            { value:"b", label: "Des moyens permettant de garantir la confidentialité, l'intégrité, la disponibilité et la résilience constantes des systèmes et des services de traitement"},
            { value:"c", label: "Des moyens permettant de rétablir la disponibilité des données à caractère personnel et l'accès à celles-ci dans des délais appropriés en cas d'incident physique ou technique"},
            { value:"d", label: "Une procédure visant à tester, à analyser et à évaluer régulièrement l'efficacité des mesures techniques et organisationnelles pour assurer la sécurité du traitement"}
          ]}/>
          

          <Input type="hr" icon="fa-random" label="Transferts"/>
          <Input bind:value={obj.isTransfers} set={false} type="switch" lg icon="fa-globe" label="Transfert de données vers un pays tiers ou à une organisation internationale" option="oui"/>
          {#if obj.isTransfers}
            <Input bind:value={obj.transfersSafeguards} set={[]} type="checkbox" icon="fa-mailbox" label="Garanties des transferts" helpBefore="Une carte du monde présentant les législations de protection des données est à votre disposition sur le site de la CNIL. Sinon, vous devrez encadrer juridiquement vos transferts pour assurer la protection des donnée sà l'étranger" options={[ 
              { value: "adequat", label: "Pays adéquat"},
              { value: "privacyShield", label: "Privacy shield"},
              { value: "cct", label: "(CCT) Clauses contractuelles types de la Commission européenne", help:"Les clauses contractuelles types sont des modèles de contrats de transfert de données personnelles adoptés par la Commission européenne."},
              { value: "bcr", label: "Code de conduite, régle d'entreprise contraignante"},
              { value: "certification", label: "Certification"},
              { value: "exemptions", label: "Dérogations (art 49)"}
            ]}/>

            <Input bind:value={obj.transfersSafeguardsComment} type="textarea" rows=6 icon="fa-comment" label="Commentaire sur les garanties de transferts"/>

          {/if}

          <Input type="hr" icon="fa-check" label="Conformité"/>
          <Input bind:value={obj.compliant} set={false} lg type="switch" icon="fa-check" label="Sous-traitant conforme au RGPD" helpBefore="Vous validez que ce sous-traitant est conforme au différents points requis par le RGPD. Il apparaitra dans votre politique de traitement des données personelles." option="oui"/>
          <Input bind:value={obj.statusComment} type="textarea" rows=6 label="Commentaires" helpBefore="Détail, motif de validation ou de non-validation, précisions..."/>
     



          <!-- audit-->
          <Input type="hr" icon="fa-clipboard-list-check"
            label="Audit du sous traitant"
            help="Cette partie, optionnelle, vous permet d'évaluer vos risques avec ce sous-traitant"
          />
          <Input bind:value={obj.isAudit} set={false} type="switch" lg icon="fa-badge-check" label="Afficher l'audit" option="oui"/>
          
          {#if obj.isAudit}
            <Input bind:value={obj.audit.warranties} set={[]} type="checkbox"
              label="Garanties"
              helpBefore=""
              options={[ 
                { value:"knowledge", label: "Le sous-traitant dispose-t-il des connaissances spécialisées pour assurer la sécurité et la conformité des opérations de traitement conformément au RGPD"},
                { value:"ressources", label: "Le sous-traitant dispose-t-il des ressources techniques suffisantes pour assurer le respect de ses obligations RGPD (équipes, matériels, systèmes informatiques)"},
                { value:"audit", label: "Le sous-traitant a-t-il mené des audits de conformité RGPD ?"},
                { value:"learning", label: "Le sous-traitant a-t-il formé ses personnels internes à la conformité RGPD"},
                { value:"remediation", label: "Le sous-traitant a-t-il mis en oeuvre des mesures organisationnelles suffisantes pour s'assurer du respect du RGPD"}
            ]}/>
            <Input bind:value={obj.audit.contract} set={[]} type="checkbox"
              label="Contrat de sous-traitance"
              helpBefore=""
              options={[ 
                { value:"contract", label: "Existe-t-il un contrat entre le responsable de traitement et le sous-traitant"},
                { value:"written", label: "Ce contrat se présente sous forme écrite (papier ou page internet dédiée)"},
                { value:"object", label: "Le contrat définit l’objet du traitement"},
                { value:"period", label: "Le contrat définit la durée du traitement"},
                { value:"datas", label: "Le contrat précise le type de données personnelles traitées"},
                { value:"categories", label: "Le contrat précise les catégories de personnes concernées"},
                { value:"obligations", label: "Le contrat précise les obligations du responsable du traitement"},
                { value:"rights", label: "Le contrat précise les droits du responsable du traitement"}
            ]}/>
            <Input bind:value={obj.audit.clauses} set={[]} type="checkbox"
              label="Clauses"
              helpBefore=""
              options={[ 
                { value:"instruction", label: "Indiquez s'il existe une clause qui impose au sous-traitant de ne traiter des données personnelles que sur instruction du responsable de traitement (ex: le sous-traitant s'engage à ne traiter des données personnelles que sur instruction du responsable de traitement)"},
                { value:"disclosure", label: "Indiquez les personnels du sous-traitant sont soumis à une obligation de confidentialité pour la manipulation des données personnelles"},
                { value:"security", label: "Le contrat prévoit que le sous-traitant s'engage à assurer la sécurité des données personnelles"},
                { value:"rights", label: "Le contrat prévoit que le sous-traitant aide le responsable à gérer les demandes de droits des personnes"},
                { value:"help", label: "Le contrat prévoit que le sous-traitant aide le responsable au respect des obligations des art. 32 à 36"},
                { value:"reversibility", label: "Le contrat prévoit une clause de réversibilité (la fin de prestation et la récupération des données)"},
                { value:"delete", label: "Le contrat prévoit une clause indiquant que le sous-traitant s'engage à détruire les données personnelles en fin de prestation"},
                { value:"evidence", label: "Le contrat prévoit que le sous-traitant mette à disposition du responsable de traitement tous les éléments nécessaires pour démontrer sa conformité au RGPD"},
                { value:"audit", label: "Le contrat prévoit une clause autorisant la réalisation d'audits, et d'inspections par le responsable du traitement"},
                { value:"breach", label: "Le contrat prévoit une clause indiquant que le sous-traitant informe le responsable si une instruction constitue une violation"}
            ]}/>
            <Input bind:value={obj.audit.subcontract} set={[]} type="checkbox"
              label="Sous-traitance de second niveau"
              helpBefore=""
              options={[ 
                { value:"subcontractForbidden", label: "Le contrat de sous-traitance prévoit que le sous-traitant ne recrute pas un autre sous-sous-traitant sans l'autorisation écrite préalable, spécifique ou générale, du responsable du traitement"},
                { value:"subcontactObligations", label: "Le contrat prévoit qu'en cas de recourt à un sous-sous-traitant, les mêmes obligations lui soient imposées"}
            ]}/>
          {/if}

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>