<script>
  import { customerId, customer, modal, alert } from './stores.js'
  import { api } from './h.js'
  
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import Wrong from './G_Wrong.svelte'

  let baseLex="https://eur-lex.europa.eu/legal-content/FR/TXT/HTML/?uri=CELEX:32016R0679"
  let baseCnil="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/"
  let scaleProcessing="https://www.cnil.fr/fr/cnil-direct/question/reglement-europeen-un-traitement-grande-echelle-cest-quoi"
  let processorGuide="https://www.cnil.fr/sites/cnil/files/atoms/files/rgpd-guide_sous-traitant-cnil.pdf"

  let nominateDpo="https://www.cnil.fr/fr/designation-dpo"
  let notifyBreach="https://www.cnil.fr/fr/notifier-une-violation-de-donnees-personnelles"
  let cnilFines="https://www.cnil.fr/fr/les-sanctions-prononcees-par-la-cnil"
  let homeWork="https://www.cnil.fr/fr/les-questions-reponses-de-la-cnil-sur-le-teletravail"
  let usaAdequat="https://www.cnil.fr/fr/adequation-des-etats-unis-les-premieres-questions-reponses"
  let usaAdequatList="https://www.dataprivacyframework.gov/s/participant-search"


  let links=[
    {label:"Article 1 - Objet et objectifs", hash:"chapitre1#Article1"},
    {label:"Article 5 - Principes", hash:"chapitre2#Article5"},
    {label:"Article 6 - Licéité du traitement", hash:"chapitre2#Article6"},
    {label:"Article 7 - Consentement", hash:"chapitre2#Article7"},
    {label:"Article 9 - Catégories particulières de données à caractère personnel", hash:"chapitre2#Article9"},
    {label:"Article 13 - Informations à fournir lorsque des données à caractère personnel sont collectées auprès de la personne concernée", hash:"chapitre3#Article13"},
    {label:"Article 28 - Sous-traitant", hash:"chapitre4#Article28"},
    {label:"Article 30 - Registre des activités de traitement", hash:"chapitre4#Article30"},
    {label:"Article 33 - Notification à l'autorité de contrôle d'une violation de données à caractère personnel", hash:"chapitre4#Article33"},
    {label:"Article 35 - Analyse d'impact relative à la protection des données", hash:"chapitre4#Article35"},
    {label:"Article 37 - Désignation du délégué à la protection des données", hash:"chapitre4#Article37"},
    {label:"Article 77 - Droit d'introduire une réclamation auprès d'une autorité de contrôle", hash:"chapitre8#Article77"},
    {label:"Article 83 - Sanctions - Conditions générales pour imposer des amendes administratives", hash:"chapitre8#Article83"}
  ]
</script>

<div class="container-lg fsContent">

  <Title title back/>


  <div class="fsScroll">
    <hr><h3>Réglement RGPD</h3>
    <p><a href={baseLex}>RÈGLEMENT (UE) 2016/679 DU PARLEMENT EUROPÉEN ET DU CONSEIL</a></p>
    <p><a href={baseCnil}>Sommaire RGPD CNIL</a></p>

    <hr><h3>Quelques articles à bien comprendre</h3>
    {#each links as link}
      <p><a href={link.url || baseCnil + link.hash}>{link.label}</a></p>
    {/each}

    <hr><h3>Quelques définitions ou éclaircissements</h3>
    <p><a href={notifyBreach}>Notifier une violation de données personnelles</a></p>
    <p><a href={nominateDpo}>Désigner un délégué à la protection des données (DPO) ou modifier une désignation</a></p>
    <p><a href={scaleProcessing}>Un "traitement à grande échelle", c'est quoi ?</a></p>
    <p><a href={processorGuide}>Guide du sous traitant</a></p>
    <p><a href={cnilFines}>Pour information: les sanctions prononcées par la CNIL depuis la mise en place du RGPD</a></p>

    <hr><h3>Questions d'actualités</h3>
    <p><a href={usaAdequat}>Transferts de données vers les États-Unis : la Commission européenne adopte une nouvelle décision d’adéquation</a></p>
    <p><a href={usaAdequatList}>Transferts de données vers les États-Unis : liste des entités ahérentes à ce principe</a></p>
    
    <p><a href={homeWork}>Les questions-réponses de la CNIL sur le télétravail</a></p>
  </div>
</div>