<script>
  import { permissions, customers, customerId, route, wsc, conf, userId, user, token, lang, alert, menus } from './stores.js'
  import { setRoute, setRouteRoot} from './h.js'

  import Window from './G_Window.svelte'
  import Route from './G_Route.svelte'

  import Dashboard from './L_Dashboard.svelte'
  import Controllers from './L_Controllers.svelte'
  import Processings from './L_Processings.svelte'
  import Processors from './L_Processors.svelte'
  import Requests from './L_Requests.svelte'
  import Breaches from './L_Breaches.svelte'
  import Actions from './L_Actions.svelte'
  import Trainings from './L_Trainings.svelte'
  import Links from './L_Links.svelte'
  
  let localConf={
    faType:"fa-light",
    url:"https://dplex.eu",
    langs:['fr','en'],
    i18n:{},
    perms:[
      {id:"users", name:"member--manage team"}
    ],
    permsAgency:[
      {id:"customers", name:"agency--manage agency customers"},
      {id:"users", name:"agency--manage agency users"}
    ],
    account:{
      withDomains:false,
      withBilling:true,
      withApi:false,
      withDPO:false,
      withLogs:true,
      withComparePlan:false,
      withLicence:true,
      withLicenceDetail:true
    },
    agency:{
      withLicenceAgencyCustomer:true,
      withLicenceAgencyCustomerDetail:true
    },
    cname:"customer.dplex.eu"
  }

  $: $menus=[
    {route: '/dashboard', name: '', icon:'home', valid:$customers},
    {route: '/homenocustomer', name: '', icon:'home', valid:!$customers},
    {route: '/controllers', name: 'Controllers', icon:'building', valid:$customers},
    {route: '/processings', name: 'Processings', icon:'cogs', valid:$customers},
    {route: '/processors', name: 'Processors', icon:'industry-windows', valid:$customers},
    {route: '/requests', name: 'Requests', icon:'question-circle ', valid:$customers},
    {route: '/breaches', name: 'Breaches', icon:'thunderstorm', valid:$customers},
    {route: '/actions', name: 'Actions', icon:'list-check', valid:$customers},
    {route: '/trainings', name: 'Trainings', icon:'graduation-cap', valid:$customers},
    {route: '/account', name: 'Account', icon:'wrench', valid:$permissions.admin || $permissions.users || $permissions.informations},
    {route: '/links', name: 'Links', icon:'link', valid:$customers},
    {route: '/agency', name: 'Agency', icon:'globe', valid:$user.perms?.valid},
    {route: '/translations', name: 'Translations', icon:'university', valid:$user.perms?.translation}
  ]
</script>

<Window {localConf}>
  {#key $customerId}
    <Route path="/homenocustomer" fsContent>
      Hello home no customer
    </Route>


    <Route path="/dashboard" fsContent>
      <Dashboard/>
    </Route>

    <Route path="/controllers/*" fsContent>
      <Controllers/>
    </Route>

    <Route path="/processings/*" fsContent>
      <Processings/>
    </Route>

    <Route path="/processors/*" fsContent>
      <Processors/>
    </Route>

    <Route path="/requests/*" fsContent>
      <Requests/>
    </Route>

    <Route path="/breaches/*" fsContent>
      <Breaches/>
    </Route>

    <Route path="/actions/*" fsContent>
      <Actions/>
    </Route>

    <Route path="/trainings/*" fsContent>
      <Trainings/>
    </Route>
  {/key}

  <Route path="/links" fsContent>
    <Links/>
  </Route>

</Window>