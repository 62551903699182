<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/trainings/edit")
  }

  async function bulk(){
    setRoute("/trainings/bulk")
  }

  async function edit(id){
    setRoute(`/trainings/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/trainings/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/trainings")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/trainings/get/${id}`)
    obj={ ...{audit:{}, ...obj }}
  }

  isSame("listTrainings","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listTrainings", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/trainings/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/trainings/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/trainings")
    }}
  }

  let controllers=[]
  getControllers()
  async function getControllers(){
    controllers = await api(`/app/customers/controllers/list`)
  }

  function getController(id){
    let ret
    ret=getById(controllers,id)
    return ret.name || '-'
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/trainings/tags/list')
  }

</script>


<CustomerBanner/>


<Route path="/trainings/*" pathHide="/trainings/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'status', title:'g--By status', desc:true},
        { id:'controller', title:'g--By controller'},
        { id:'plannedDate', title:'g--By planned Date', desc:true},
        { id:'closeDate', title:'g--By close Date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--trainings" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      {id:"controller", title:"g--Controller", format:getController},
      {id:"name", title:"g--name"},
      {id:"plannedDate", title:"g--Planned date", format:'dmy'},
      {id:"closeDate", title:"g--Close date", format:'dmy'},
      {id:"status", title:"g--Status", format:'i18n', i18nPrefix:'training--'}
    ]}/>
  </div>
</Route>


<Route path="/trainings/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="training" detail={obj.name} back="/trainings"/>

      <div class="anchor" on:click={anchor}>
        <span><I18n>Description</I18n></span> |
        <span><I18n>Personnes concernées</I18n></span>
      </div>

        <Form fsContent norow>
          {#if obj.id}
            <div class="d-flex justify-content-end">
              <Button validity {update} class="col-auto"/>
            </div>
          {/if}
          <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="Description"/>
          <Input bind:value={obj.controller} type="select" required icon="fa-building" label="Responsable du traitement" helpBefore="Le responsable de traitement est la personne morale (entreprise, commune, etc.) ou physique qui détermine les finalités et les moyens d'un traitement, c'est à dire l'objectif et la façon de le réaliser" options={controllers}/>
          
          <Input bind:value={obj.shortId} readonly type="text" icon label="Référence"/>
          
          <Input bind:value={obj.name} required type="text" icon label="Nom de l'action de formation/sensibilisation"/>
          <Input bind:value={obj.description} type="textarea" rows=10 label="Détail de la formation"/>
          
          <Input inline bind:value={obj.status} set={"draft"} type="radio" icon="fa-tag" label="status" options={[ 
            { value: "draft", label: "training--draft"},
            { value: "planned", label: "training--planned"},
            { value: "closed", label: "training--closed"}
            ]}/>
            
          <Input set={new Date()} class="col-sm-6" bind:value={obj.plannedDate} icon type="date" label="Date prévisionnelle" required/>

          {#if obj.status=="closed"}
            <Input set={new Date()} class="col-sm-6" bind:value={obj.closeDate} icon type="date" label="Date de réalisation" required/>
          {/if}

          <Input inline bind:value={obj.format} set={"normal"} type="radio" icon="fa-highlighter" label="Format" options={[ 
            { value: "physical", label: "training--physical"},
            { value: "confcall", label: "training--confcall"},
            { value: "elearning", label: "training--elearning"},
            { value: "email", label: "training--email"},
            { value: "paper", label: "training--paper"},
            { value: "other", label: "Autres actions de formation"}
          ]}/>
          {#if inArray("other", obj.format)}
            <Input bind:value={obj.subjectsOther} required type="textarea" rows=6 label="Autre format de formation, veuillez préciser"/>
          {/if}

          <Input bind:value={obj.owner} required type="textarea" rows=5 label="Responsable"/>

          <Input type="hr" icon="fa-user-tag" label="Personnes concernées"/>
          <Input bind:value={obj.subjects} inCol="col-sm-6" set={[]} type="checkbox" icon="fa-users" label="Personnes concernées" options={[ 
            { value: "employees", label: "Tous les collaborateurs"},
            { value: "customers", label: "Responsables de service"},
            { value: "suppliers", label: "Direction"},
            { value: "prospects", label: "Comex"},
            { value: "candidates", label: "Codir"},
            { value: "providers", label: "Prestataires"},
            { value: "trainees", label: "Stagiaires"},
            { value: "temporary ", label: "Intérimaires"},
            { value: "other", label: "Autres personnes concernées"}
          ]}/>
          {#if inArray("other", obj.subjects)}
            <Input bind:value={obj.subjectsOther} required type="textarea" rows=6 label="Autres personnes concernées, veuillez préciser"/>
          {/if}

          <Input bind:value={obj.volume} required type="number" icon label="Nombre de personnes concernées par cette action de formation"/>
     




          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>