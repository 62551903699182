<script>
  import { conf, permissions, modal } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={requestDate:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/requests/edit")
  }

  async function bulk(){
    setRoute("/requests/bulk")
  }

  async function edit(id){
    setRoute(`/requests/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/requests/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/requests")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/requests/get/${id}`)
    obj={ ...{dpo:{}, ...obj }}
  }

  isSame("listRequests","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listRequests", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/requests/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/requests/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/requests")
    }}
  }

  let controllers=[]
  getControllers()
  async function getControllers(){
    controllers = await api(`/app/customers/controllers/list`)
  }

  function getController(id){
    let ret
    ret=getById(controllers,id)
    return ret.name || '-'
  }

  let processings=[]
  getProcessings()
  async function getProcessings(){
    processings = await api(`/app/customers/processings/list`)
  }

  let tags=[]
  getTags()
  async function getTags(){
    //tags = await api('/app/customers/requests/tags/list')
  }

</script>


<CustomerBanner/>


<Route path="/requests/*" pathHide="/requests/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'subject', title:'g--By subject'},
        { id:'controllerId', title:'g--By controller'},
        { id:'status', title:'g--By status', desc:true},
        { id:'requestDate', title:'g--By request Date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--requests" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      {id:"controllerId", title:"g--Controller", format:getController},
      {id:"subject", title:"g--subject"},
      {id:"openDate", title:"g--open date", format:'dmy'},
      {id:"status", title:"g--status", format:'i18n', i18nPrefix:'request--'}
    ]}/>
  </div>
</Route>


<Route path="/requests/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="request" detail={obj.subject} back="/requests"/>

      <div class="anchor" on:click={anchor}>
        <span><I18n>Description</I18n></span> |
        <span><I18n>Personne concernée</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="Description"/>
          <Input bind:value={obj.controllerId} type="select" required icon="fa-building" label="Responsable du traitement concerné" options={controllers}/>
          <Input class="col-lg-2" set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="Référence"/>

          <Input class="col-lg-10" bind:value={obj.subject} required type="text" icon label="Sujet"/>

          <Input set={new Date()} class="col-lg-6" bind:value={obj.openDate} icon type="datetime" label="Date de la demande" required/>

           <Input bind:value={obj.status} set={"new"} type="radio" icon="fa-tags" label="status" options={[ 
            { value: "new", label: "request--new"},
            { value: "check", label: "request--check"},
            { value: "inprogress", label: "request--inprogress"},
            { value: "processed", label: "request--processed"}
          ]}/>
          <Input set={new Date()} class="col-lg-6" bind:value={obj.closeDate} icon type="datetime" label="Date de traitement de la demande" required/>
          <Input bind:value={obj.description} required type="textarea" rows=10 label="Description de la demande"/>
          <Input bind:value={obj.answer} type="textarea" rows=10 label="Indiquez la réponse fournie à la demande"/>


          <Input type="hr" icon="fa-user" label="Personne concernée"/>
          <Input bind:value={obj.email} type="email" icon label="Email"/>
          <Input bind:value={obj.address} type="textarea" rows=6 label="Autre coordonnées" helpBefore="Adresse, téléphone..."/>

          <Input bind:value={obj.adult} set={true} lg type="switch" icon="fa-child" label="Personne concernée majeure" option="oui"/>
          
          <Input type="hr" icon="fa-cogs" label="Traitements concernés"/>
          <Input bind:value={obj.processings} set={[]} type="checkbox" icon="fa-tags" label="Traitements concernés" options={processings}/>

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>