<script>
  import { conf, permissions, modal  } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, getById, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    let licence=await api(`/app/customers/licence/get`)
    if(!licence.available?.processings) $modal={
      cancel:true,
      title:i18n("licence--No more processings"), 
      msg:i18n("licence--No more processings msg"), 
      color:'warning', actionLabel:i18n("licence--Upgrade"), 
      action:async ()=>{
        setRoute("/account/licence")
    }, cancel:()=>{}}
    else setRoute("/processings/edit")
  }

  async function bulk(){
    setRoute("/processings/bulk")
  }

  async function edit(id){
    setRoute(`/processings/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/processings/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/processings")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/processings/get/${id}`)
    obj={ ...{audit:{}, ...obj }}
  }

  isSame("listProcessings","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listProcessings", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/processings/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/processings/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/processings")
    }}
  }

  let processors=[]
  getProcessors()
  async function getProcessors(){
    processors = await api(`/app/customers/processors/list`)
  }

  let controllers=[]
  getControllers()
  async function getControllers(){
    controllers = await api(`/app/customers/controllers/list`)
  }

  function getController(id){
    let ret
    ret=getById(controllers,id)
    return ret.name || '-'
  }

  function getCover(value, obj, field){
    let ret=0
    if(obj.lawfulness?.length) ret+=1
    if(obj.subjects?.length) ret+=1
    if(obj.recipients?.length) ret+=1
    //if(obj.storagePeriod) ret+=1
    if(Object.values(obj.storagePeriod).every(value => !!value)) ret+=1
    if(obj.security?.length) ret+=1
    if(obj.rights?.length) ret+=1

    ret=Math.round(ret*100/6)
    let color
    if(ret==100) color="success"
    if(ret<100) color="warning"
    if(ret<50) color="danger"

    return `<span class="text-${color}">${ret}%</span>`
  }

  let tags=[]
  getTags()
  async function getTags(){
    tags = await api('/app/customers/processings/tags/list')
  }
</script>

<CustomerBanner/>


<Route path="/processings/*" pathHide="/processings/edit/:id?" fsContent>
  <div class="container-fluid fsContent">
    
    <Title title withCustomerSelector back/>

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={$permissions.bulk?bulk:false}

      bind:filter filters={[
        { filter: {}, title:'g--All' },
        { filter: {valid:true}, title:'g--In use' },
        //{ filter: {compliant:true}, title:'g--Compliant' },
        //{ filter: {compliant:false}, title:'g--Not compliant' },
        { filter: {coverStatus:"satisfying"}, title:"g--Satisfying"},
        { filter: {coverStatus:"not satisfying"}, title:"g--Not satisfying"},
        { filter: {coverStatus:"to review"}, title:"g--To review"},
        { filter: {public:true}, title:'g--Public' },
        { filter: {public:false}, title:'g--Not public' },
        { filter: {valid:false}, title:'g--Draft' },
        { filter: {isCategoriesSensitive:true}, title:'Sensibles' },
        { filter: {isPia:true}, title:'PIA' }
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'controller', title:'g--By controller'},
        { id:'date', title:'g--By date', desc:true},
        { id:'tags', title:'g--By tags', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--processings" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      //{id:"compliant", title:"Compliant", format:"badge"},
      {id:"coverStatus", title:"g--cover status"},
      {id:"cover", title:"g--Cover", format:getCover},
      {id:"valid", title:"g--In use"},
      {id:"public", title:"g--Public"},
      {id:"name", title:"g--Name"},
      {id:"controller", title:"g--Controller", format:getController},
      {id:"tags", title:"g--Tags", format:"tags"},
      {id:"isCategoriesSensitive", title:"Sensibles"},
      {id:"isPia", title:"Pia"},
      {id:"date", title:"g--Update date", format:'dmy'},
      {id:"statusComment", title:"Commentaires"}
    ]}/>
  </div>
</Route>


<Route path="/processings/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="processing" detail={obj.name} back="/processings"/>

      <div class="anchor" on:click={anchor}>
        <span><I18n>Général</I18n></span> |
        <span><I18n>Licéité</I18n></span> |
        <span><I18n>Personnes concernées</I18n></span> |
        <span><I18n>Données</I18n></span> |
        <span><I18n>Destinataires</I18n></span> |
        <span><I18n>Durée de conservation</I18n></span> |
        <span><I18n>Securité</I18n></span> |
        <span><I18n>Transferts</I18n></span> |
        <span><I18n>Exercices des droits</I18n></span> |
        <span><I18n>Analyse d'impact</I18n></span> |
        <span><I18n>Couverture</I18n></span> |
        <span><I18n>Audit du traitement</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="Général"/>
          <Input bind:value={obj.controller} type="select" required icon="fa-building" label="Responsable du traitement" helpBefore="Le responsable de traitement est la personne morale (entreprise, commune, etc.) ou physique qui détermine les finalités et les moyens d'un traitement, c'est à dire l'objectif et la façon de le réaliser" options={controllers}/>
       
          <Input bind:value={obj.name} required type="text" icon="fa-clipboard-list-check" label="Nom du traitement" multilang translator/>
          <Input set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="Référence"/>
          <Input bind:value={obj.valid} set={true} lg type="switch" icon="fa-play-circle" label="Ce traitement est actif" helpBefore="Ce traitement est actuellement utilisé" option="oui"/>
          <Input bind:value={obj.tags} canAdd options={tags} type="tag" label="g--tags"/>
          <Input bind:value={obj.purposes} type="textarea" rows=6 icon="fa-comment" label="Finalités du traitement" helpBefore="Finalités auquelles sont destinées les données à caractère personnel" multilang translator/>
          

          <Input type="hr" icon="fa-gavel" label="Licéité"/>
          <Input bind:value={obj.lawfulness} inCol="col-sm-6 col-12" set={[]} type="checkbox" icon="fa-balance-scale" label="Base légale du traitement" helpBefore="Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions est remplie" options={[ 
            { value: "a", label: "Consentement", help:"La personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques"},
            { value: "b", label: "Exécution d'un contrat", help:"Le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles prises à la demande de celle-ci"},
            { value: "c", label: "Obligation légale", help:"Le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du traitement est soumis"},
            { value: "d", label: "Intérêts vitaux", help:"Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique"},
            { value: "e", label: "Intérêt public", help:"Le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de l'exercice de l'autorité publique dont est investi le responsable du traitement"},
            { value: "f", label: "Intérêts légitimes", help:"Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant"},
          ]}/>
          {#if inArray("f", obj.lawfulness)}
            <Input bind:value={obj.legitimateInterests} type="textarea" rows=6 label="Décrire les intérêts légitimes poursuivis par le responsable du traitement ou par un tiers" multilang translator/>
          {/if}
          <Input bind:value={obj.legitimateInterestsDetail} type="textarea" rows=6 label="Précisions sur la base légale du traitement" helpBefore="(Facultatif) Article, texte, réglement..."/>
 

          <Input type="hr" icon="fa-user-tag" label="Personnes concernées"/>
          <Input bind:value={obj.subjects} inCol="col-sm-6" set={[]} type="checkbox" icon="fa-users" label="Personnes concernées" options={[ 
            { value: "employees", label: "Salariés"},
            { value: "trainees", label: "Stagiaires"},
            { value: "temporary ", label: "Intérimaires"},
            { value: "candidates", label: "Candidats"},
            { value: "customers", label: "Clients"},
            { value: "providers", label: "Prestataires"},
            { value: "processors", label: "Sous-traitants"},
            { value: "suppliers", label: "Fournisseurs"},
            { value: "prospects", label: "Prospects"},
            { value: "other", label: "Autres personnes concernées"}
          ]}/>
          {#if inArray("other", obj.subjects)}
            <Input bind:value={obj.subjectsOther} required type="textarea" rows=6 label="Autres personnes concernées, veuillez préciser" multilang translator/>
          {/if}


          <Input type="hr" icon="fa-database" label="Données"/>
          <Input bind:value={obj.categories} set={[]} type="checkbox" icon="fa-tags" label="Catégories de données à caractère personnel traitées" options={[ 
            { value: "civil", label: "État civil, identité, données d'identification, photo...)"},
            { value: "personnal", label: "Vie personnelle (situation familiale, enfants à charge, habitudes de vie...)"},
            { value: "business", label: "Professionnelle :  téléphones, courrier électronique, fonctions ou titre"},
            { value: "pro", label: "Vie professionnelle (matricule interne, date d’ancienneté, numéro de matricule poste occupé, fonction, position dans l'organigramme de l'organisation...)"},
            { value: "cv", label: "Parcours professionnel (CV)"},
            { value: "eco", label: "Informations d'ordre économique et financier (revenus, situation financière, situation fiscale...)"},
            { value: "log", label: "Données de connexion (adresse IP, logs...)"},
            { value: "loc", label: "Données de localisation (déplacements, données GPS, GSM...)"},
            { value: "nir", label: "Numéro de Sécurité Sociale (ou NIR)"},
            { value: "other", label: "Autres données"}
          ]}/>
          {#if inArray("other", obj.categories)}
            <Input bind:value={obj.categoriesOther} required type="textarea" rows=6 label="Autres données, veuillez préciser" multilang translator/>
          {/if}

          <Input type="hr" icon="fa-user-tag" label="Données sensibles"/>
          <Input bind:value={obj.isCategoriesSensitive} set={false} lg type="switch" icon="fa-triangle-exclamation" label="Données sensibles" option="oui"/>
    
          {#if obj.isCategoriesSensitive}
            <Input bind:value={obj.categoriesSensitive} set={[]} type="checkbox" icon="fa-tags" label="Catégories de données sensibles à caractère personnel traitées" options={[ 
              { value: "racial", label: "Origine raciale ou ethnique"},
              { value: "politic", label: "Opinions politiques"},
              { value: "union", label: "Appartenance syndicale"},
              { value: "conviction", label: "Convictions religieuses ou philosophiques"},
              { value: "genetic", label: "Données génétiques"},
              { value: "biometric", label: "Données biométriques aux fins d'identifier une personne physique de manière unique"},
              { value: "health", label: "Données concernant la santé"},
              { value: "sexual", label: "Vie sexuelle ou l'orientation sexuelle"},
              { value: "condamnations", label: "Données relatives à des condamnations pénales ou infractions (article 10)"},
              { value: "other", label: "Autres données sensibles"}
            ]}/>
            {#if inArray("other", obj.categoriesSensitive)}
              <Input bind:value={obj.categoriesSensitiveOther} required type="textarea" rows=6 label="Autres données sensibles, veuillez préciser" multilang translator/>
            {/if}

            <Input bind:value={obj.lawfulnessSensitive} set={[]} type="checkbox" icon="fa-ballot-check" label="Conditions s'appliquant et permettant le traitement des catégories particulières de données (article 9)" options={[ 
              { value: "a", label: "La personne concernée a donné son consentement explicite au traitement de ces données à caractère personnel pour une ou plusieurs finalités spécifiques, sauf lorsque le droit de l'Union ou le droit de l'État membre prévoit que l'interdiction visée au paragraphe 1 ne peut pas être levée par la personne concernée."},
              { value: "b", label: "Le traitement est nécessaire aux fins de l'exécution des obligations et de l'exercice des droits propres au responsable du traitement ou à la personne concernée en matière de droit du travail, de la sécurité sociale et de la protection sociale, dans la mesure où ce traitement est autorisé par le droit de l'Union, par le droit d'un État membre ou par une convention collective conclue en vertu du droit d'un État membre qui prévoit des garanties appropriées pour les droits fondamentaux et les intérêts de la personne concernée."},
              { value: "c", label: "Le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique, dans le cas où la personne concernée se trouve dans l'incapacité physique ou juridique de donner son consentement"},
              { value: "d", label: "Le traitement est effectué, dans le cadre de leurs activités légitimes et moyennant les garanties appropriées, par une fondation, une association ou tout autre organisme à but non lucratif et poursuivant une finalité politique, philosophique, religieuse ou syndicale, à condition que ledit traitement se rapporte exclusivement aux membres ou aux anciens membres dudit organisme ou aux personnes entretenant avec celui-ci des contacts réguliers en liaison avec ses finalités et que les données à caractère personnel ne soient pas communiquées en dehors de cet organisme sans le consentement des personnes concernées"},
              { value: "e", label: "Le traitement porte sur des données à caractère personnel qui sont manifestement rendues publiques par la personne concernée"},
              { value: "f", label: "Le traitement est nécessaire à la constatation, à l'exercice ou à la défense d'un droit en justice ou chaque fois que des juridictions agissent dans le cadre de leur fonction juridictionnelle"},
              { value: "g", label: "Le traitement est nécessaire pour des motifs d'intérêt public important, sur la base du droit de l'Union ou du droit d'un 'État membre qui doit être proportionné à l'objectif poursuivi, respecter l'essence du droit à la protection des données et prévoir des mesures appropriées et spécifiques pour la sauvegarde des droits fondamentaux et des intérêts de la personne concernée"},
              { value: "h", label: "Le traitement est nécessaire aux fins de la médecine préventive ou de la médecine du travail, de l'appréciation de la capacité de travail du travailleur, de diagnostics médicaux, de la prise en charge sanitaire ou sociale, ou de la gestion des systèmes et des services de soins de santé ou de protection sociale sur la base du droit de l'Union, du droit d'un État membre ou en vertu d'un contrat conclu avec un professionnel de la santé et soumis aux conditions et garanties visées au paragraphe 3"},
              { value: "i", label: "Le traitement est nécessaire pour des motifs d'intérêt public dans le domaine de la santé publique, tels que la protection contre les menaces transfrontalières graves pesant sur la santé, ou aux fins de garantir des normes élevées de qualité et de sécurité des soins de santé et des médicaments ou des dispositifs médicaux, sur la base du droit de l'Union ou du droit de l'État membre qui prévoit des mesures appropriées et spécifiques pour la sauvegarde des droits et libertés de la personne concernée, notamment le secret professionnel"},
              { value: "j", label: "Le traitement est nécessaire à des fins archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques, conformément à l'article 89, paragraphe 1, sur la base du droit de l'Union ou du droit d'un État membre qui doit être proportionné à l'objectif poursuivi, respecter l'essence du droit à la protection des données et prévoir des mesures appropriées et spécifiques pour la sauvegarde des droits fondamentaux et des intérêts de la personne concernée"},
              { value: "3", label: "Les données à caractère personnel visées au paragraphe 1 peuvent faire l'objet d'un traitement aux fins prévues au paragraphe 2, point h), si ces données sont traitées par un professionnel de la santé soumis à une obligation de secret professionnel conformément au droit de l'Union, au droit d'un État membre ou aux règles arrêtées par les organismes nationaux compétents, ou sous sa responsabilité, ou par une autre personne également soumise à une obligation de secret conformément au droit de l'Union ou au droit d'un État membre ou aux règles arrêtées par les organismes nationaux compétents"}
            ]}/>
          {/if}


          <Input type="hr" icon="fa-person-carry" label="Destinataires"/>
          <Input bind:value={obj.recipients} set={[]} inCol="col-sm-6" type="checkbox" icon="fa-mailbox" label="Destinataires des données" helpBefore="Personne physique ou morale, autorité publique, service ou tout autre organisme qui reçoit communication de données à caractère personnel, qu'il s'agisse ou non d'un tiers (Article 4 §9)" options={[ 
            { value: "internal", label: "Services internes"},
            { value: "processors", label: "Sous-traitants"},
            { value: "authority", label: "Autorité publique"},
            { value: "international", label: "Destinataires dans des pays tiers ou organisations internationales"},
            { value: "partners", label: "Partenaires institutionnels ou commerciaux"},
            { value: "other", label: "Autres destinataires"}
          ]}/>
          <Input bind:value={obj.recipientsDetail} type="textarea" rows=6 label="Précisions sur les destinataires" multilang translator/>

          {#if inArray("processors", obj.recipients)}
            <Input bind:value={obj.processors} set={[]} type="checkbox" icon="fa-users-cog" label="Sous-traitants" helpBefore="Les sous-traitants sont définis dans le menu sous-traitants" options={processors}/>
          {/if}
          
          <Input bind:value={obj.softwares} type="textarea" rows=6 label="Logiciels ou outils utilisés" helpBefore="Précisez éventuellement les outils et logiciels utilisés"/>

          <Input type="hr" icon="fa-shield-check" label="Durée de conservation"/>
          <Input bind:value={obj.storagePeriod} icon="fa-business-time" type="textarea" rows=6 label="Précisez la durée de conservation" helpBefore="Durée de conservation des données à caractère personnel ou, les critères utilisés pour déterminer cette durée, ainsi que les délais prévus pour effacement des différentes catégories de données. Si il y a des durées différentes pour chaque catégories de données, préciser" multilang translator/>
   

          <!--Input type="hr" icon="fa-arrow-up-small-big" label="Echelle du traitement"/>
          <Input bind:value={obj.large} set={false} type="switch" lg icon="fa-cloud" label="Traitement à grande échelle" helpBefore="Pour déterminer si un traitement peut être qualifié de traitement à grande échelle, il faut tenir compte du nombre de personnes concernées, des catégories de données traitées (leur volume et/ou leur nature), de la durée ou de la permanence des activités du traitement, de l'étendue géographique des activités du traitement" option="oui"/-->
    

          <Input type="hr" icon="fa-shield-check" label="Securité"/>
          <Input inCol="col-sm-6 col-12" bind:value={obj.security} set={[]} type="checkbox" icon="fa-lock-alt" label="Sécurité du traitement organisationnelle et technique" helpBefore="Le responsable du traitement mettent en oeuvre les mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté au risque" options={[ 
            { value:"a", label: "La pseudonymisation et le chiffrement des données à caractère personnel"},
            { value:"b", label: "Des moyens permettant de garantir la confidentialité, l'intégrité, la disponibilité et la résilience constantes des systèmes et des services de traitement"},
            { value:"c", label: "Des moyens permettant de rétablir la disponibilité des données à caractère personnel et l'accès à celles-ci dans des délais appropriés en cas d'incident physique ou technique"},
            { value:"d", label: "Une procédure visant à tester, à analyser et à évaluer régulièrement l'efficacité des mesures techniques et organisationnelles pour assurer la sécurité du traitement"},
            { value:"e", label: "Sensibilisation spécifique des utilisateurs"},
            { value:"f", label: "Encadrement des habilitations"},
            { value:"g", label: "Authentification des utilisateurs"},
            { value:"h", label: "Journalisation des accès"},
            { value:"i", label: "Sécurisation des postes de travail"},
            { value:"j", label: "Protection du réseau informatique interne"},
            { value:"k", label: "Sécurisation de l'informatique mobile"},
            { value:"l", label: "Sécurisation des serveurs"},
            { value:"m", label: "Installation d'un système de sauvegarde"},
            { value:"n", label: "Sécurisation spécifique aux sites web"},
            { value:"o", label: "Sécurisation des archives"},
            { value:"p", label: "Encadrement de la maintenance"},
            { value:"q", label: "Destruction sécurisée des données"},
            { value:"r", label: "Encadrement de la sous-traitance"},
            { value:"s", label: "Utilisation du chiffrement"},
            { value:"t", label: "Sécurisation des accès physiques"}
          ]}/>


          <Input type="hr" icon="fa-random" label="Transferts"/>
          <Input bind:value={obj.isTransfers} set={false} type="switch" lg icon="fa-globe" label="Transferts de données vers un pays tiers ou à une organisation internationale" option="oui"/>
          
          {#if obj.isTransfers}
            <Input bind:value={obj.transfersSafeguards} set={[]} type="checkbox" icon="fa-mailbox" label="Garanties des transferts" helpBefore="Une carte du monde présentant les législations de protection des données est à votre disposition sur le site de la CNIL. Sinon, vous devrez encadrer juridiquement vos transferts pour assurer la protection des donnée sà l'étranger" options={[ 
              { value: "adequat", label: "Pays adéquat"},
              { value: "privacyShield", label: "Privacy shield"},
              { value: "cct", label: "(CCT) Clauses contractuelles types de la Commission européenne", help:"Les clauses contractuelles types sont des modèles de contrats de transfert de données personnelles adoptés par la Commission européenne."},
              { value: "bcr", label: "Code de conduite, régle d'entreprise contraignante"},
              { value: "certification", label: "Certification"},
              { value: "exemptions", label: "Dérogations (art 49)"}
            ]}/>
            <Input bind:value={obj.transfersSafeguardsComment} type="textarea" rows=6 icon="fa-comment" label="Commentaire sur les garanties de transferts"/>
          {/if}

          <Input type="hr" icon="fa-random" label="Exercices des droits"/>
    
          <Input bind:value={obj.rights} set={['information','access','rectification','limit','erasure','automated','portability']} type="checkbox" icon="fa-mailbox" label="Droits exercables" options={[ 
            { value: "information", label: "Droit d'être informé"},
            { value: "access", label: "Droit d'accès"},
            { value: "rectification", label: "Droit de rectification"},
            { value: "erasure", label: "Droit à l'effacement"},
            { value: "limit", label: "Droit à la limitation du traitement", help:"Une personne physique peut demander au responsable de traitement de conserver les données qu’il détient la concernant, mais sans pouvoir les utiliser. Ce droit s’applique si :\nl’exactitude des données à caractère personnel est contestée par la personne concernée, ce durant une durée permettant de vérifier l'exactitude des données,\nles données font l’objet d’un traitement illicite,\nles données personnelles ne sont plus nécessaires à la personne concernée pour la constatation, l'exercice ou la défense de droits en justice,\nla personne concernée s'est opposée au traitement en vertu de son droit d’opposition."},
            { value: "automated", label: "Droit d'opposition et prise de décision individuelle automatisée (profilage)", help:"Le RGPD instaure explicitement un droit d’opposition au profilage à des fins de prospection. L’utilisation d’un algorithme visant à analyser les données d’un individu afin d’évaluer son intérêt pour certains types de produits et services, la probabilité qu’il les achète, qu’il se comporte de telle ou telle manière ou encore qu’il soit à tel ou tel endroit, peut être qualifiée de profilage, et donc faire l’objet d’un droit d’opposition.\nLe droit d’opposition au profilage ne s’applique pas lorsque le profilage :\nest nécessaire dans le cadre d’un contrat entre la personne concernée et le responsable de traitement,\nest autorisé par le droit de l’Union ou d’un État membre,\nest fondé sur le consentement explicite de l’individu."},
            { value: "portability", label: "Droit à la portabilité des données", help:"Les personnes physiques peuvent demander au responsable de traitement une copie des données personnelles les concernant sous un format structuré, courant et électronique. L’objectif est de permettre aux utilisateurs de changer de fournisseur sans perdre de données ( exemple pour changement de plateforme numérique, avec transfert des données à une autre plateforme)"}
          ]}/>

          <Input bind:value={obj.isRightsProcedure} set={false} type="switch" lg icon="fa-book" label="Procédure d'exercice des droits existante" option="oui"/>
          
          <Input type="hr" icon="fa-target"
            label="Analyse d'impact"
          />
          <Input bind:value={obj.isPia} set={false} lg type="switch" icon="fa-check" label="Une analyse d'impact est-elle nécessaire" helpBefore="Elle est obligatoire pour les traitements susceptibles d’engendrer des risques élevés." option="oui"/>

          <Input bind:value={obj.piaCriterias} set={[]} type="checkbox" icon="fa-mailbox" label="Critères PIA" options={[ 
            { value: "scoring", label: "Évaluation/scoring (y compris le profilage)"},
            { value: "automated", label: "Décision automatique avec effet légal ou similaire"},
            { value: "monitoring", label: "Surveillance systématique"},
            { value: "personnal", label: "Collecte de données sensibles ou données à caractère hautement personnel"},
            { value: "scale", label: "Collecte de données personnelles à large échelle", help:"Pour déterminer si un traitement peut être qualifié de traitement à grande échelle, il faut tenir compte du nombre de personnes concernées, des catégories de données traitées (leur volume et/ou leur nature), de la durée ou de la permanence des activités du traitement, de l'étendue géographique des activités du traitement"},
            { value: "cross", label: "Croisement des données"},
            { value: "vulnerability", label: "Personnes vulnérables (patients, personnes agées, enfants, etc.)"},
            { value: "innovation", label: "Usage innovant (utilisation d'une nouvelle technologie)"},
            { value: "exclusion", label: "Exclusion du bénéfice d'un droit/contrat"}
            ]}/>

          <Input bind:value={obj.isPiaExempted} set={false} lg type="switch" icon="fa-check" label="Ce traitement fait partie des traitements disposant d'une exemption de réalisation de PIA" option="oui"/>
          <Input bind:value={obj.isPiaRequired} set={false} lg type="switch" icon="fa-check" label="Ce traitement fait partie des opérations de traitement pour lesquelles une AIPD est requise" option="oui"/>

          <Input type="hr" icon="fa-check"
            label="Couverture"
          />

          <Input inline bind:value={obj.coverStatus} set={"to review"} type="radio" icon="fa-check" label="g--cover status" options={[ 
            { value: "not satisfying", label: "g--not satisfying"},
            { value: "satisfying", label: "g--satisfying"},
            { value: "to review", label: "g--to review"}
          ]}/>


          <!--Input bind:value={obj.compliant} set={false} lg type="switch" icon="fa-check" label="Traitement conforme au RGPD" helpBefore="Vous validez que ce traitement est conforme au différents points requis par le RGPD. Il apparaitra dans votre politique de traitement des données personelles." option="oui"/-->
          <Input bind:value={obj.statusComment} type="textarea" rows=6 label="Commentaires" helpBefore="Détail, motif de validation ou de non-validation, précisions..."/>
          <Input bind:value={obj.public} set={false} type="switch" lg icon="fa-bullhorn" label="Publique" helpBefore="Cette fiche est exposée dans votre politique publique de gestion des données personnelles" option="oui"/>
          


          <!-- audit-->
          <Input type="hr" icon="fa-clipboard-list-check"
            label="Audit du traitement"
            help="Cette partie, optionnelle, vous permet d'auditer différents points de conformité sur ce traitement. Le niveau de conformité global ne peut pas être garantit par cette évaluation uniquement déclarative"
          />
          <Input bind:value={obj.isAudit} set={false} type="switch" lg icon="fa-badge-check" label="Afficher l'audit" option="oui"/>
          
          {#if obj.isAudit}
            <Input bind:value={obj.audit.warrantiesLaw} set={[]} type="checkbox"
              label="Principes licéité"
              helpBefore="(article 5) Les principes de licéité, loyauté, transparence sont respectés"
              options={[ 
                { value:"a", label: "Vous avez cartographié tous les points de collecte de données personnelles"},
                { value:"b", label: "Les données à caractère personnel sont traitées de manière licite"},
                { value:"c", label: "Elles sont traitées de manière loyale"},
                { value:"d", label: "Elles sont traitées de manière transparente par rapport à la personne concernée"}
            ]}/>
            <Input bind:value={obj.audit.warrantiesObject} set={[]} type="checkbox"
              label="Principes finalités"
              helpBefore="(article 5) Les finalités du traitement sont clairement définies"
              options={[ 
                { value:"a", label: "Les finalités du traitement sont déterminées"},
                { value:"b", label: "Les finalités du traitement sont explicites"},
                { value:"c", label: "Les finalités du traitement sont légitimes"}
            ]}/>
            <Input bind:value={obj.audit.warrantiesMinify} set={[]} type="checkbox"
              label="Principes minimisation"
              helpBefore=""
              options={[ 
                { value:"a", label: "Lister l'ensemble des données collectées et traitées"},
                { value:"b", label: "Vérifier que chaque donnée est strictement nécessaire à la finalité indiquée"},
            ]}/>
            <Input bind:value={obj.audit.warrantiesCorrect} set={[]} type="checkbox"
              label="Principes d'exactitude"
              helpBefore="(article 5) Le principe d'exactitude est respecté"
              options={[ 
                { value:"a", label: "Vérifier l'existence de processus permettant de maintenir les données à jour"},
                { value:"b", label: "Vérifier que les données puissent être rectifiées rapidement, le cas échéant"},
            ]}/>
            <Input bind:value={obj.audit.warrantiesLimit} set={[]} type="checkbox"
              label="(article 5) Principes de limitation"
              helpBefore="Respect du principe de limitation de la conservation"
              options={[ 
                { value:"a", label: "Vérifier les critères utilisés pour définir la durée de conservation des données"},
                { value:"b", label: "Vérifier que la durée de conservation soit bien adéquate et minimale"},
                { value:"c", label: "Vérifier que les données sont bien effacées au terme des délais indiqués"}
            ]}/>
            <Input bind:value={obj.audit.legalBase} set={[]} type="checkbox"
              label="Base légale"
              helpBefore="Le traitement repose sur une base légale (article 6)"
              options={[ 
                { value:"a", label: "Le traitement repose sur une base légale clairement définie"}
            ]}/>
            <Input bind:value={obj.audit.legalConsent} set={[]} type="checkbox"
              label="Consentement"
              helpBefore="Les conditions applicables au consentement sont respectées (article 6)"
              options={[ 
                { value:"a", label: "Vous disposez d'une preuve que le consentement a bien été donné par la personne concernée"},
                { value:"b", label: "Le consentement peut facilement être retiré par la personne concernée"},
                { value:"c", label: "Une fois le consentement retiré, les données de la personne concernée ne seront plus traitées"}
            ]}/>
            <Input bind:value={obj.audit.sensitive} set={[]} type="checkbox"
              label="Données sensibles"
              helpBefore="L'article 9 du règlement limite la possibilité de collecter et traiter des données qui révèlent l'origine raciale ou ethnique, les opinions politiques, les convictions religieuses ou philosophiques ou l'appartenance syndicale, les données génétiques, les données biométriques, les données concernant la santé ou concernant la vie sexuelle ou l'orientation sexuelle d'une personne physique. Il existe 10 cas spécifiques autorisant le traitement de ces données, l'audit doit donc vérifier deux points : soit vérifier qu'aucune donnée article 9 ne fait l'objet d'un traitement, soit que leur traitement s'inscrit dans le cadre d'une des exceptions prévues par la loi. L'article 10 interdit la collecte et le traitement de données relatives à des condamnations pénales et aux infractions, sauf exception prévues par la loi"
              options={[ 
                { value:"a", label: "Aucune donnée article 9 n'est traitée ou le traitement repose sur une exception légale"},
                { value:"b", label: "Aucune donnée article 10 n'est traitée ou le traitement repose sur une exception légale"}
            ]}/>
            <Input bind:value={obj.audit.mentions12} set={[]} type="checkbox"
            label="Mentions Légales"
            helpBefore="(article 12) Les mentions légales sont indiquées de manière transparente"
            options={[ 
              { value:"a", label: "Les mentions légales sont indiquées d'une façon concise"},
              { value:"b", label: "Les mentions légales sont indiquées d'une façon transparente"},
              { value:"c", label: "Les mentions légales sont indiquées d'une façon compréhensible"},
              { value:"d", label: "Les mentions légales sont aisément accessible"}
            ]}/>
            <Input bind:value={obj.audit.mentions13} set={[]} type="checkbox"
              label="(article 13) Mentions Légales"
              helpBefore="Le responsable du traitement doit s'assurer que les mentions légales sont affichées à l'utilisateur avant la collecte de ses données personnelles"
              options={[ 
                { value:"a", label: "Les mentions légales sont affichées avant la collecte des données personnelles"},
                { value:"b", label: "L'utilisateur est informé de l'identité et des coordonnées du responsable et de son DPO"},
                { value:"c", label: "L'utilisateur est informé des finalités du traitement"},
                { value:"d", label: "L'utilisateur est informé des destinataires des données et de la base légale du traitement"},
                { value:"e", label: "L'utilisateur est informé de l'existence de transferts le cas échéant"},
                { value:"f", label: "L'utilisateur est informé de la durée de conservation des données"},
                { value:"g", label: "L'utilisateur est informé de ses droits et de la possibilité de retirer son consentement"},
                { value:"h", label: "L'utilisateur est informé de la possibilité de faire un recourt"},
                { value:"i", label: "L'utilisateur est informé du fait que la fourniture de ces informations est obligatoire ou non"},
                { value:"j", label: "L'utilisateur est informé de l'existence d'une prise de décision automatisée ou non"}
            ]}/>

            <Input bind:value={obj.audit.rights} set={[]} type="checkbox"
              label="Droit des personnes"
              helpBefore="Les principes de licéité, loyauté, transparence sont respectés"
              options={[ 
                { value:"a", label: "(article 12) Le responsable du traitement facilite l'exercice des droits par les personnes concernées"},
                { value:"b", label: "(article 12) Le responsable du traitement est en mesure de répondre aux demandes de droit d'accès"},
                { value:"c", label: "(article 12) Aucun paiement n'est exigé lors d'une demande de droit d'accès"},
                { value:"d", label: "(article 16) La personne concernée peut obtenir la rectification de données personnelles inexactes"},
                { value:"e", label: "(article 22) Aucun profilage n'est effectué ou il est mis en oeuvre."}
            ]}/>
            <Input bind:value={obj.audit.processors} set={[]} type="checkbox"
              label="Responsable du traitement et sous-traitance"
              helpBefore="Les principes de licéité, loyauté, transparence sont respectés"
              options={[ 
                { value:"a", label: "(article 24) Le responsable du traitement est clairement identifé"},
                { value:"b", label: "(article 26) Les obligations relatives au responsable conjoint sont remplies (le cas échéant)"},
                { value:"c", label: "(article 28) Tous les sous-traitants intervenant dans le traitement sont clairement identifiés"},
                { value:"d", label: "(article 28) Tous les sous-traitants intervenant dans le traitement présentent les garanties suffisantes"},
                { value:"e", label: "(article 28) Un contrat article 28 a été conclu avec chaque sous-traitant intervenant dans le traitement"},
                { value:"f", label: "(article 30) Tous les champs du registre ont bien été renseignés par rapport au traitement"}
            ]}/>
            <Input bind:value={obj.audit.breach} set={[]} type="checkbox"
              label="Notification des violations de données personnelles (article 33)"
              helpBefore="Le responsable du traitement doit s'assurer de notifier les violations de données personnelles à l'autorité nationale de contrôle sous 72h à la découverte de la notification. Afin de pouvoir respecter ces obligations, il est donc important que le personnel soit sensibilisé à cette problématique et que les incidents de sécurité soient tracés continuellement"
              options={[ 
                { value:"a", label: "Il existe un processus de notification des violations de données personnelles."},
                { value:"b", label: "L'organisation dispose d'un processus permettant de tracer les attaques informatiques"},
                { value:"c", label: "Les personnels de l'organisation ont été sensibilisés à la remontée d'incidents"},
                { value:"d", label: "L'organisation est en mesure de notifier ces incidents sous 72h"}
            ]}/>
            <Input bind:value={obj.audit.pia} set={[]} type="checkbox"
              label="Analyse d'impact"
              helpBefore="(article 35) La nécessité d'une étude d'impact a été vérifiée"
              options={[ 

            ]}/>
            <Input bind:value={obj.audit.warrantiesLaw} set={[]} type="checkbox"
              label=""
              helpBefore=""
              options={[ 
                { value:"a", label: "Déterminer si le traitement est sur la liste établie par la CNIL"},
                { value:"b", label: "Déterminer si le traitement est susceptible de présenter un risque élevé"},
                { value:"c", label: "Déterminer si le traitement est sujet à PIA en vertu d'une autre exception"},
                { value:"d", label: "L'étude d'impact contient une description des opérations de traitement"},
                { value:"e", label: "Elle contient une description des finalités et de l'intérêt légitime, le cas échéant"},
                { value:"f", label: "Elle contient une analyse de la nécessité et de la proportionnalité du traitement"},
                { value:"g", label: "Elle contient une évaluation des risques pour les personnes concernées"},
                { value:"h", label: "Elle contient une description des mesures en oeuvre pour faire face à ces risques"},
                { value:"i", label: "Elle contient une description des mesures de sécurité en oeuvre"},
                { value:"j", label: "Le responsable de traitement a demandé conseil à son DPO, le cas échéant"},
                { value:"k", label: "L'avis des personnes concernées a été sollicité au sujet du traitement"},
                { value:"l", label: "Une ré-évaluation régulière de l'étude est programmée au regard des risques"}
            ]}/>
            <Input bind:value={obj.audit.transferts} set={[]} type="checkbox"
              label="Transferts"
              helpBefore="(article 44) L'existence d'un transfert hors UE a été vérifiée"
              options={[ 
                { value:"a", label: "Un schéma d'architecture du traitement a été élaboré"},
                { value:"b", label: "La localisation géographique des données est clairement déterminée"},
                { value:"c", label: "Si un transfert existe, il est réalisé en conformité au règlement européen"},
                { value:"d", label: "Le transfert repose sur une exception simple ou a fait l'objet d'une validation juridique spécifique"}
            ]}/>
          {/if}

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>