<script>
  import { conf, permissions, modal } from './stores.js'
  import { api, translate, i18n, setRoute, sleep, inArray, isSame, genCode, anchor } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs=[], obj={}, keyword="", sortBy={name:1}, filter={}, tagsSelected=[]

  $: list(keyword, filter, tagsSelected, sortBy)
    
  async function add(){
    setRoute("/breaches/edit")
  }

  async function bulk(){
    setRoute("/breaches/bulk")
  }

  async function edit(id){
    setRoute(`/breaches/edit/${id}`)
  }

  async function update(){
    await api(`/app/customers/breaches/set`,{obj: obj})
    //await sleep(100)
    list()
    getTags()
    setRoute("/breaches")
  }

  async function get(id){
    obj={}
    if(id) obj=await api(`/app/customers/breaches/get/${id}`)
    obj={ ...{dpo:{}, ...obj }}
  }

  isSame("listBreaches","clean")
  async function list(...checkArgs){
    if(checkArgs.length && isSame("listBreaches", checkArgs)) return

    let existentTags=tagsSelected?tagsSelected.filter((t)=>{return tags.includes(t)}):false
    let req={ ...filter, ...(existentTags.length?{tags: { $in: existentTags}}:{})}
    objs = await api(`/app/customers/breaches/list`, {
      request: {...req},
      keyword:keyword,
      sortBy: sortBy
    })
  }

  async function del(){
    $modal={cancel:true, title:`${i18n("g--Delete")} ${i18n(obj.name)}`, msg:i18n("g--Confirm delete"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/customers/breaches/delete/${obj.id}`)
      //await sleep(100)
      list()
      setRoute("/breaches")
    }}
  }

  let processors=[]
  getProcessors()
  async function getProcessors(){
    processors = await api(`/app/customers/processors/list`)
  }

  let tags=[]
  getTags()
  async function getTags(){
    //tags = await api('/app/customers/breaches/tags/list')
  }

</script>


<CustomerBanner/>


<Route path="/breaches/*" pathHide="/breaches/edit/:id?" fsContent>
  <div class="container-lg fsContent">
    
    <Title title withCustomerSelector back/>

    {#if obj.id}
      <div class="d-flex justify-content-end">
        <Button validity {update} class="col-auto"/>
      </div>
    {/if}

    <FilterLine {tags} bind:tagsSelected search bind:keyword 
      create={$permissions?add:false} createBulk={false}

      bind:filter filters={[
      ]}

      bind:sortBy sortBys={[
        { id:'name', title:'g--By name'},
        { id:'breachDate', title:'breaches--By breach date', desc:true}
      ]}
    />

    <List onSelect={edit} refresh={()=>list()} title="g--breaches" {objs} fields={[
      {id:'shortId', title:'g--Reference'},
      {id:"name", title:"g--Name"},
      {id:"breachDate", title:"breaches--breach date", format:'dmy'},
      {id:"isAuthorityNotified", title:"breaches--authority notified"},
      {id:"isSubjectsNotified", title:"breaches--subjects notified"}
    ]}/>
  </div>
</Route>


<Route path="/breaches/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

    <div class="container fsContent">
      <Title title="breache" detail={obj.name} back="/breaches"/>

      <div class="anchor" on:click={anchor}>
        <span><I18n>Description</I18n></span> |
        <span><I18n>Données concernées</I18n></span>
        <span><I18n>Personnes concernées</I18n></span> |
        <span><I18n>Conséquences</I18n></span>
      </div>

      <Form fsContent norow>
        {#if obj.id}
          <div class="d-flex justify-content-end">
            <Button validity {update} class="col-auto"/>
          </div>
        {/if}
        <div class="row fsScroll">
          <Input type="hr" icon="fa-info-square" label="Description"/>
          <Input set={genCode(5)} bind:value={obj.shortId} readonly type="text" icon label="Référence"/>
          <Input bind:value={obj.name} required type="text" icon label="Description courte"/>
          <Input set={new Date()} class="col-sm-6" bind:value={obj.breachDate} icon type="date" label="Date de la violation de données" required/>

          <Input bind:value={obj.authorityMustBeNotified} set={false} lg type="switch" icon="fa-memo-circle-info" label="L'autorité de contrôle doit être notifiée ?" option="oui"/>
          
          <Input bind:value={obj.isAuthorityNotified} set={false} lg type="switch" icon="fa-triangle-exclamation" label="L'autorité de contrôle a été notifiée" option="oui"/>
          {#if obj.isAuthorityNotified}
            <Input set={new Date()} class="col-sm-6" bind:value={obj.authorityNotifiedDate} icon type="date" label="Date de la notification à l'autorité de contrôle" required/>
          {/if}
          <Input bind:value={obj.description} type="textarea" rows=6 icon="fa-comment" label="Description"/>
      

          <Input type="hr" icon="fa-database" label="Données concernées" />
          <Input bind:value={obj.volume} required type="number" icon label="Volume de données concernées par la violation"/>
          <Input bind:value={obj.categories} set={[]} type="checkbox" icon="fa-tags" label="Catégories de données concernées" options={[ 
            { value: "civil", label: "État civil, identité, données d'identification, photo...)"},
            { value: "personnal", label: "Vie personnelle (habitudes de vie, situation familiale...)"},
            { value: "pro", label: "Vie professionnelle (matricule interne, poste occupé, fonction, position dans l'organigramme de l'organisation...)"},
            { value: "cv", label: "Parcours professionnel (CV)"},
            { value: "eco", label: "Informations d'ordre économique et financier (revenus, situation financière, situation fiscale...)"},
            { value: "log", label: "Données de connexion (adresse IP, logs...)"},
            { value: "loc", label: "Données de localisation (déplacements, données GPS, GSM...)"},
            { value: "nir", label: "Numéro de Sécurité Sociale (ou NIR)"},
            { value: "other", label: "Autres données"}
          ]}/>
          {#if inArray("other", obj.categories)}
            <Input bind:value={obj.categoriesOther} required type="textarea" rows=6 label="Autres données, veuillez préciser"/>
          {/if}


          <Input bind:value={obj.isCategoriesSensitive} set={false} lg type="switch" icon="fa-triangle-exclamation" label="Données sensibles" option="oui"/>
          {#if obj.isCategoriesSensitive}
            <Input bind:value={obj.categoriesSensitive} set={[]} type="checkbox" icon="fa-tags" label="Catégories de données sensibles concernées" options={[ 
              { value: "racial", label: "Origine raciale ou ethnique"},
              { value: "politic", label: "Opinions politiques"},
              { value: "union", label: "Appartenance syndicale"},
              { value: "conviction", label: "Convictions religieuses ou philosophiques"},
              { value: "genetic", label: "Données génétiques"},
              { value: "biometric", label: "Données biométriques aux fins d'identifier une personne physique de manière unique"},
              { value: "health", label: "Données concernant la santé"},
              { value: "sexual", label: "Vie sexuelle ou l'orientation sexuelle"},
              { value: "condamnations", label: "Données relatives à des condamnations pénales ou infractions (article 10)"},
              { value: "other", label: "Autres données sensibles"}
            ]}/>
            {#if inArray("other", obj.categoriesSensitive)}
              <Input bind:value={obj.categoriesSensitiveOther} required type="textarea" rows=6 label="Autres données sensibles, veuillez préciser"/>
            {/if}
          {/if}


          <Input type="hr" icon="fa-user-tag" label="Personnes concernées"/>
          <Input bind:value={obj.subjects} inCol="col-sm-6" set={[]} type="checkbox" icon="fa-users" label="Personnes concernées" options={[ 
            { value: "employees", label: "Salariés"},
            { value: "processors", label: "Sous-traitants"},
            { value: "customers", label: "Clients"},
            { value: "suppliers", label: "Fournisseurs"},
            { value: "prospects", label: "Prospects"},
            { value: "candidates", label: "Candidats"},
            { value: "providers", label: "Prestataires"},
            { value: "trainees", label: "Stagiaires"},
            { value: "temporary ", label: "Intérimaires"},
            { value: "other", label: "Autres personnes concernées"}
          ]}/>
          {#if inArray("other", obj.subjects)}
            <Input bind:value={obj.subjectsOther} required type="textarea" rows=6 label="Autres personnes concernées, veuillez préciser"/>
          {/if}

          <Input type="hr" icon="fa-info-square" label="Conséquences"/>
          <Input bind:value={obj.consequences} type="textarea" rows=6 icon="fa-comment" label="Conséquences probables de la violation"/>
          <Input bind:value={obj.remediation} type="textarea" rows=6 icon="fa-comment" label="Mesures" help="Mesures prises ou proposées pour remédier à la violation, y compris, les mesures pour en atténuer les éventuelles conséquences négatives"/>
      
          <Input bind:value={obj.isSubjectsNotified} set={false} lg type="switch" icon="fa-triangle-exclamation" label="Les personnes concernées ont été notifiées" option="oui"/>
          {#if obj.isSubjectsNotified}
            <Input set={new Date()} class="col-sm-6" bind:value={obj.subjectsNotified} icon type="date" label="Date de la notification aux personnes concernées" required/>
          {/if}

          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </div>
      </Form>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>